import { Action, Computed, action, computed } from "easy-peasy";

/** "Enums" */

export type CertificateType = "NASBA" | "GenericCPE";
export type CourseIncludesEnum =
	| "Skill Lab"
	| "Practice Test"
	| "Assessment"
	| "CPEs"
	| "Featured";
export type LearningAreasEnum = "Audit" | "Cyber" | "IT";
export type LearningOptionsEnum = "On Demand" | "Instructor Led";
export type LevelEnum = "EntryLevel" | "Intermediate" | "Advanced";

/** Types */

export type Prerequisite = {
	prerequisiteExternalId: string;
	prerequisiteSlug: string;
	prerequisiteTitle: string;
};
export type SubjectMatterExpert = {
	subjectMatterExpertBio: string;
	subjectMatterExpertCertification: string[];
	subjectMatterExpertName: string;
	subjectMatterExpertPhoto: string;
};
export type Episode = {
	description?: string | null;
	length: number;
	order: number;
	thumbnail?: string | null;
	thumbnailMed?: string | null;
	thumbnailSm?: string | null;
	title: string;
	transcript: string;
	vimeoId?: string | null;
};
export type Topic = {
	episodes: Episode[];
	title: string;
};
export type Course = {
	assessment: boolean;
	certificateType: CertificateType | null;
	creditHours: number | null;
	description?: string | null;
	descriptionMD?: string | null;
	externalId: string;
	instructorLength?: string | null;
	learningAreas: LearningAreasEnum[];
	learningOptions: LearningOptionsEnum[];
	length: number;
	level?: LevelEnum | null;
	licensed: boolean;
	name: string;
	order: number;
	practiceExam: boolean;
	prerequisites: Prerequisite[];
	subjectMatterExperts: SubjectMatterExpert[];
	subtitle?: string | null;
	tagAndCategoryNames: string[];
	tags: Tag[];
	tagUrl?: string | null;
	targetAudience?: string | null;
	topics: Topic[];
	url: string;
	vLab: boolean;
	featured: boolean;
};
export type TagCourse = {
	assessment: boolean;
	certificateType: CertificateType | null;
	creditHours: number | null;
	courseLength: number;
	description?: string | null;
	descriptionMD?: string | null;
	exams: boolean;
	externalId: string;
	hidden: boolean;
	instructorLength?: string | null;
	learningAreas: LearningAreasEnum[];
	learningOptions: LearningOptionsEnum[];
	length: number;
	level?: LevelEnum | null;
	licensed: boolean;
	name: string;
	order: number;
	practiceExam: boolean;
	prerequisites: Prerequisite[];
	productionEnd?: string | null;
	productionStart?: string | null;
	subjectMatterExperts: SubjectMatterExpert[];
	subtitle?: string | null;
	tagUrl: string;
	targetAudience?: string | null;
	url: string;
	vLabs: boolean;
};
export type TagCategoryTag = {
	contentFull?: string | null;
	contentShort?: string | null;
	externalId: string;
	tagname: string;
	url: string;
};
export type LearningArea = {
	displayName: string;
	name: LearningAreasEnum;
	slug: string;
	sortPriority: number;
	tagCategoryIds: string[];
};
export type TagCategory = {
	contentFull?: string | null;
	contentShort?: string | null;
	externalId: string;
	learningArea: LearningAreasEnum;
	tagcategoryname: string;
	tags: TagCategoryTag[];
	url: string;
	weight: number;
};
export type Tag = {
	contentFull: string;
	contentShort: string;
	courses: TagCourse[];
	externalId: string;
	learningAreas: LearningAreasEnum[];
	tagname: string;
	url: string;
	weight: number;
};
export type ProductPackageCourse = {
	id: string;
	name: string;
	slug: string | null;
	position: number;
	description: string | null;
	tags: string | null;
	topics: Array<string> | null;
	promotion: string | null;
};
export type CourseCount = Record<LearningAreasEnum, number>;

export type Filter = {
	courseId?: string;
	courseIncludes?: CourseIncludesEnum[];
	courseLearningArea?: LearningAreasEnum;
	courseLearningOptions?: LearningOptionsEnum[];
	search?: string;
	sort?: "ASC" | "DESC";
};

export type Schema = {
	/** State */
	courses: Course[];
	productCourses: ProductPackageCourse[];
	filter: Filter;
	learningAreas: LearningArea[];
	tagCategories: TagCategory[];
	tags: Tag[];
	/** Computed states */
	filteredCourses: Computed<Schema, Course[]>;
	filteredProductCourses: Computed<Schema, ProductPackageCourse[]>;
	courseExact: Computed<Schema, Course | undefined>;
	courseCounts: Computed<Schema, CourseCount>;
	/** Thunks */
	// onFiltersChanged: ThunkOn<Schema>;
	/** Actions */
	setCourses: Action<Schema, Course[]>;
	setProductCourses: Action<Schema, ProductPackageCourse[]>;
	setTagCategories: Action<Schema, TagCategory[]>;
	setTags: Action<Schema, Tag[]>;
	setLearningAreas: Action<Schema, LearningArea[]>;
	setFilter: Action<Schema, Filter>;
	clearFilter: Action<Schema>;
};

/** Schema */

/** Describes the shape of the store, and actions that
 * can be taken to manipulate the data. */
const schema: Schema = {
	/** State */
	courses: [],
	productCourses: [],
	filter: {},
	learningAreas: [],
	tagCategories: [],
	tags: [],

	/** Computed states */
	filteredCourses: computed((state) => {
		const currentFilter = state.filter;
		let filteredCoursesArray = state.courses;

		// Filter by Learning Area
		if (currentFilter.courseLearningArea) {
			const learningAreaName = currentFilter.courseLearningArea;
			filteredCoursesArray = filteredCoursesArray.filter((course) =>
				course.learningAreas.includes(learningAreaName)
			);
		}

		// Filter by Learning Option(s)
		if (currentFilter.courseLearningOptions) {
			const learningOptions = currentFilter.courseLearningOptions;
			filteredCoursesArray = filteredCoursesArray.filter((course) =>
				learningOptions.every(
					(option) => course.learningOptions.indexOf(option) !== -1
				)
			);
		}

		// Filter by Course includes
		if (currentFilter.courseIncludes) {
			const courseIncludes = currentFilter.courseIncludes;
			filteredCoursesArray = filteredCoursesArray.filter((course) => {
				let passed = true;
				const hasAssessment = course.assessment;
				const hasExam = course.practiceExam;
				const hasLab = course.vLab;
				const hasCPEs = course.creditHours;
				const isFeatured = course.featured;

				courseIncludes.forEach((value) => {
					if (!passed) return;
					switch (value) {
						case "Assessment":
							if (!hasAssessment) {
								passed = false;
							}
							break;
						case "Practice Test":
							if (!hasExam) {
								passed = false;
							}
							break;
						case "Skill Lab":
							if (!hasLab) {
								passed = false;
							}
							break;
						case "CPEs":
							if (!hasCPEs) {
								passed = false;
							}
							break;
						case "Featured":
							if (!isFeatured) {
								passed = false;
							}
							break;
					}
				});

				return passed;
			});
		}

		// Filter by search string on name, description or tags
		if (currentFilter.search) {
			const searchString = currentFilter.search.toLowerCase();

			filteredCoursesArray = filteredCoursesArray.filter((course) => {
				let passed = false;

				if (course.name.toLowerCase().includes(searchString)) {
					passed = true;
				}
				if (course.description?.toLowerCase().includes(searchString)) {
					passed = true;
				}
				if (
					course.descriptionMD?.toLowerCase().includes(searchString)
				) {
					passed = true;
				}
				if (
					course.tags.some((tag) =>
						tag.tagname.toLowerCase().includes(searchString)
					)
				) {
					passed = true;
				}

				return passed;
			});
		}

		// Run sorts last, after fetching all correct courses
		if (currentFilter.sort) {
			const sortResults =
				currentFilter.sort === "ASC" ? [-1, 1] : [1, -1];

			filteredCoursesArray = filteredCoursesArray.sort(
				(courseA, courseB) => {
					switch (courseA.name < courseB.name) {
						case true:
							return sortResults[0];
						case false:
							return sortResults[1];
						default:
							return 0;
					}
				}
			);
		}

		return filteredCoursesArray;
	}),

	filteredProductCourses: computed((state) => {
		const currentFilter = state.filter;
		let filteredProductCoursesArray = state.productCourses;

		// Filter by search string on name, description or tags
		if (currentFilter.search) {
			const searchString = currentFilter.search.toLowerCase();

			filteredProductCoursesArray = filteredProductCoursesArray.filter(
				(course) => {
					let passed = false;

					if (course.name.toLowerCase().includes(searchString)) {
						passed = true;
					}
					if (
						course.description?.toLowerCase().includes(searchString)
					) {
						passed = true;
					}
					if (
						course.description?.toLowerCase().includes(searchString)
					) {
						passed = true;
					}

					return passed;
				}
			);
		}

		// Run sorts last, after fetching all correct courses
		if (currentFilter.sort) {
			const sortResults =
				currentFilter.sort === "ASC" ? [-1, 1] : [1, -1];

			filteredProductCoursesArray = filteredProductCoursesArray.sort(
				(courseA, courseB) => {
					switch (courseA.name < courseB.name) {
						case true:
							return sortResults[0];
						case false:
							return sortResults[1];
						default:
							return 0;
					}
				}
			);
		}

		return filteredProductCoursesArray;
	}),
	courseExact: computed((state) => {
		const currentFilter = state.filter;

		if (currentFilter.courseId) {
			return state.courses.find(
				(course) => course.externalId === currentFilter.courseId
			);
		}
	}),
	courseCounts: computed((state) => {
		const auditCourses = state.courses.filter((course) =>
			course.learningAreas.includes("Audit")
		);
		const cyberCourses = state.courses.filter((course) =>
			course.learningAreas.includes("Cyber")
		);
		const itCourses = state.courses.filter((course) =>
			course.learningAreas.includes("IT")
		);

		return {
			Audit: auditCourses.length,
			Cyber: cyberCourses.length,
			IT: itCourses.length,
		};
	}),

	/** Thunks */
	/** Thunks are side effects based on actions being ran or on state save. */
	// onFiltersChanged: thunkOn(
	// 	(actions) => [actions.setFilter, actions.clearFilter],
	// 	(actions, payload, { getState }) => {
	// 		// console.log(`onFiltersChanged triggered by `, payload);
	// 		// actions.doSomething(getState().something);
	// 	}
	// ),

	/** Actions */
	setCourses: action((state, payload) => {
		state.courses = payload;
	}),
	setProductCourses: action((state, payload) => {
		state.productCourses = payload;
	}),
	setTagCategories: action((state, payload) => {
		state.tagCategories = payload;
	}),
	setTags: action((state, payload) => {
		state.tags = payload;
	}),
	setLearningAreas: action((state, payload) => {
		state.learningAreas = payload;
	}),
	setFilter: action((state, payload) => {
		state.filter = payload;
	}),
	clearFilter: action((state) => {
		state.filter = {};
	}),
};

/** Exports */

export default schema;
